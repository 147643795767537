'use client'

import { useState } from 'react'

export interface ChatConfig {
  chatAPI: string
}

export function useClientConfig() {
  const chatAPI = process.env.NEXT_PUBLIC_CHAT_API!
  const [config, setConfig] = useState<ChatConfig>({
    chatAPI,
  })

  return config
}
