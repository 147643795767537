'use client'

import { Button } from '@material-tailwind/react'
import { E164Number } from 'libphonenumber-js/types.cjs'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

import Checkbox from '../Forms/Checkbox'
import PreferenceContainer from './PreferenceContainer'

interface Inputs {
  mobilePhone: E164Number
  preferences: {
    enable_sms_nudges: boolean
  }
}

interface Props {
  updateState?: any
}

const SmsNudges = (props: Props) => {
  const t = useTranslations('Preferences')
  const { data: session, update } = useSession()
  const parsedPhoneNumber = session?.user.mobilePhone && parsePhoneNumber(session?.user.mobilePhone, 'US')

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
    control,
    setValue,
    watch,
  } = useForm<Inputs>({})
  const enabled = watch('preferences.enable_sms_nudges')
  const [savedPhoneNumber, setSavedPhoneNumber] = useState<boolean>(false)

  useEffect(() => {
    if (session?.user?.preferences?.enable_sms_nudges) {
      setValue('preferences', { enable_sms_nudges: true }, { shouldTouch: true })
    }
  }, [session?.user.preferences?.enable_sms_nudges, setValue])

  useEffect(() => {
    if (session?.user?.mobilePhone) {
      const parsedPhoneNumber = parsePhoneNumber(session.user.mobilePhone, 'US')
      if (parsedPhoneNumber) {
        setValue('mobilePhone', parsedPhoneNumber?.number, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      } else {
        setValue('mobilePhone', '' as E164Number)
      }
    }
  }, [session?.user.mobilePhone, setValue])

  const handlePhoneNumberSubmit = async (data: Inputs) => {
    console.log(data)
    await update({
      mobilePhone: data.mobilePhone,
      preferences: { enable_sms_nudges: data.preferences.enable_sms_nudges },
    })
    setSavedPhoneNumber(true)
    setTimeout(() => setSavedPhoneNumber(false), 3000)
  }
  const handleFocus = () => {
    setTimeout(() => {
      document.getElementById('phoneNumber')?.focus()
    }, 100)
  }
  return (
    <PreferenceContainer title={t('smsReminders')} roles={[UserRoles.FAMILY, UserRoles.STUDENT]}>
      <form className="mt-4" onSubmit={handleSubmit(handlePhoneNumberSubmit)}>
        <div>
          <Checkbox type="checkbox" {...register('preferences.enable_sms_nudges')}>
            {t('optInToSms')}
          </Checkbox>
        </div>

        {(enabled || session?.user.preferences?.enable_sms_nudges) && (
          <>
            <p className="text-lg my-4">{t('whatIsYourMobile')}</p>
            <div className="mt-1 flex items-center gap-x-4">
              <Controller
                name="mobilePhone"
                control={control}
                rules={{
                  validate: (value) => value && isPossiblePhoneNumber(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    onChange={onChange}
                    value={value}
                    id="phoneNumber"
                    country="US"
                    international={false}
                    autoFocus
                    onClick={handleFocus}
                    style={{
                      borderRadius: '0.75rem',
                      border: '1px solid rgb(224 224 224 / var(--tw-border-opacity))',
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
        <div className="block my-4 gap-y-2">
          <Button
            className="cursor-pointer bg-white text-black text-sm font-sans mr-4"
            onClick={() => props.updateState(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            className="bg-brand-purple"
            variant="filled"
            size="lg"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {t('save')}
          </Button>
          {savedPhoneNumber && <p className="mt-1 text-sm font-semibold text-green-600">Saved!</p>}
        </div>
      </form>
    </PreferenceContainer>
  )
}

export default SmsNudges
