'use client'

import { GlobeAltIcon } from '@heroicons/react/20/solid'
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { startTransition, useState } from 'react'

import { usePathname, useRouter } from '@/i18n'
import { useSettings } from '@/providers/SettingsProvider'

import { I18N_LANGUAGE_NAMES } from '@roadmap/lib/i18n'
import type { Locales } from '@roadmap/lib/types'

const LanguageSwitcher = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const router = useRouter()
  const pathname = usePathname()
  const { data: session, update } = useSession()
  const { SUPPORTED_LOCALES } = useSettings()

  const onLanguageChange = (locale: Locales) => {
    return async () => {
      if (session?.user) {
        await update({ locale: locale })
      }
      startTransition(() => {
        router.replace(pathname, { locale: locale })
      })
    }
  }

  return (
    <Menu open={menuOpen} handler={setMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button className="cursor-pointer bg-transparent hover:shadow-none shadow-none rounded-full px-2 sm:px-3 md:px-4 xl:px-6">
          <GlobeAltIcon className="md:h-7 md:w-7 w-6 h-6 text-white" />
        </Button>
      </MenuHandler>
      <MenuList>
        <MenuItem key="en" onClick={onLanguageChange('en')}>
          English
        </MenuItem>
        {SUPPORTED_LOCALES.filter((locale) => locale !== 'en').map((locale) => {
          return (
            <MenuItem key={locale} onClick={onLanguageChange(locale)}>
              {I18N_LANGUAGE_NAMES[locale as Locales]}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default LanguageSwitcher
