import { forwardRef } from 'react'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode
}

const Checkbox = (props: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
  const { children, ...propsWithoutChildren } = props
  return (
    <label className="flex items-start gap-x-3">
      <input
        type="checkbox"
        // We use margin-top to align the checkbox with the first line of text, not all the lines of text.
        className="text-brand-purple focus:ring-brand-purple mt-[0.32rem] rounded border-gray-300 bg-gray-50 transition"
        ref={ref}
        {...propsWithoutChildren}
      />
      <p className="text-base sm-custom:text-lg">{children}</p>
    </label>
  )
}

export default forwardRef(Checkbox)
