'use client'

interface Props {
  gtmId: string
}
const GTMNoScriptEmbed = (props: Props) => {
  const { gtmId } = props
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}5`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  )
}

export default GTMNoScriptEmbed
