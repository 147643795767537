'use client'

import { XMarkIcon } from '@heroicons/react/20/solid'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { Dispatch, PropsWithChildren, SetStateAction } from 'react'

interface Props extends PropsWithChildren {
  modalIsOpen: boolean
  setModalIsOpen: Dispatch<SetStateAction<boolean>>
  title: string
}

const AvatarMenuModal = (props: Props) => {
  const { modalIsOpen, setModalIsOpen, children } = props
  return (
    <Dialog open={modalIsOpen} handler={() => setModalIsOpen(!modalIsOpen)} size="xl">
      <DialogBody>
        <div className="my-2 block text-right">
          <XMarkIcon className="inline h-6 w-6 cursor-pointer" onClick={() => setModalIsOpen(false)} />
        </div>
        <div>{children}</div>
      </DialogBody>
    </Dialog>
  )
}

export default AvatarMenuModal
