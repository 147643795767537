'use client'

import { Alert } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import { useSettings } from '@/providers/SettingsProvider'

import PreferenceContainer from './PreferenceContainer'

const BasicInfo = () => {
  const { data: session } = useSession()
  const t = useTranslations('Preferences')
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false)
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false)
  const [errorContent, setErrorContent] = useState<string>('An unknown error occurred')
  const [showWhyRequire, setShowWhyRequire] = useState<boolean>(false) // New state for toggling the div
  const { CGN_CORP_DOMAIN } = useSettings()

  const resendVerificationEmail = async () => {
    setShowErrorAlert(false)
    setShowSuccessAlert(false)
    try {
      await fetch('/api/settings/email/send-verification', {
        method: 'POST',
      })
      setShowSuccessAlert(true)
      setTimeout(() => {
        setShowSuccessAlert(false)
      }, 10000)
    } catch (e) {
      console.error('Error occurred trying to send email verification', e)
    }
  }

  return (
    <PreferenceContainer title="Basic Information">
      {showSuccessAlert && (
        <Alert color="green" onClose={() => setShowSuccessAlert(false)}>
          {t('successVerifyEmail')}
        </Alert>
      )}
      <p className="text-lg">
        {t('currentEmailAddress')}: {session?.user.email}{' '}
        {session?.user.emailVerified ? (
          <span className="text-sm text-green-700">{t('verified')}</span>
        ) : (
          <div>
            <span className="text-sm text-red-700">{t('notVerified')}</span>
            <span className="text-sm text-black">{' - '}</span>
            <span
              className="cursor-pointer text-sm text-brand-purple underline underline-offset-2"
              onClick={resendVerificationEmail}
            >
              {t('clickHereToResend')}
            </span>
          </div>
        )}
      </p>
      <div className="flex w-full flex-col">
        <p className="text-lg">
          {t('dateOfBirth')}: {session?.user.dob && new Date(session.user.dob as string).toLocaleDateString()}
        </p>
        <p className="text-sm text-gray-500">
          {t('toChangeDateOfBirth')}{' '}
          <a
            href={`mailto:support@${CGN_CORP_DOMAIN ? CGN_CORP_DOMAIN : 'collegeguidancenetwork.com'}`}
            className="underline underline-offset-2"
          >
            support@{CGN_CORP_DOMAIN ? CGN_CORP_DOMAIN : 'collegeguidancenetwork.com'}
          </a>
          .
        </p>
        <div>
          <span
            className="underline underline-offset-2 cursor-pointer text-gray-500"
            onClick={() => setShowWhyRequire((prev) => !prev)}
          >
            {t('whyDoWeRequireEmail')}
          </span>
          {showWhyRequire && <div className="text-sm mt-2">{t('whyRequireDescription')}</div>}
        </div>
      </div>
    </PreferenceContainer>
  )
}

export default BasicInfo
