'use client'

import { signOut, useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import { createContext, useContext, useEffect, useState } from 'react'

import { Locales, Settings } from '@roadmap/lib/types'

interface SettingsProviderProps extends React.PropsWithChildren {
  settings: Settings
  locale?: Locales
}

export const SettingsContext = createContext<Settings>({ SUPPORTED_LOCALES: [] })

export const SettingsProvider = ({ settings, children, locale = 'en' }: SettingsProviderProps) => {
  const { status } = useSession()
  const pathname = usePathname()
  const [currentSettings, setCurrentSettings] = useState<Settings>(settings)
  const [wasAuthenticated, setWasAuthenticated] = useState<boolean>(status === 'authenticated')

  useEffect(() => {
    const timer = setInterval(async () => {
      if (status === 'authenticated') {
        setWasAuthenticated(true)
        fetch('/api/settings', { redirect: 'manual', headers: { 'cache-control': 'no-cache', pragma: 'no-cache' } })
          .then(async (response) => {
            if (response.ok) {
              const newSettings = await response.json()
              if (JSON.stringify(currentSettings) !== JSON.stringify(newSettings)) {
                console.log('new settings', newSettings)
                setCurrentSettings(newSettings)
              } else {
                console.log('no new settings')
              }
            } else {
              const location = response.headers.get('location')
              if (
                location &&
                [302, 307, 308].includes(response.status) &&
                (location === '/' || location === '/api/auth/signin')
              ) {
                console.log('redirected to the signin page, logging the user out')
                signOut({ callbackUrl: locale === 'en' ? '/' : `/${locale}` })
              } else {
                console.error('Bad request')
              }
            }
          })
          .catch((error) => {
            console.error('Could not fetch new settings', error)
          })
      } else {
        if (status === 'unauthenticated' && wasAuthenticated && pathname !== '/' && pathname !== '/api/auth/signin') {
          console.log('User auth token has expired, redirecting them to the login page')
          setWasAuthenticated(false)
          signOut({ callbackUrl: locale === 'en' ? '/' : `/${locale}` })
        }
      }
    }, 30000)
    return () => clearTimeout(timer)
  })

  return <SettingsContext.Provider value={currentSettings}>{children}</SettingsContext.Provider>
}

export const useSettings = () => {
  const value = useContext(SettingsContext)
  return value
}

export default SettingsContext
