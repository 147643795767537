'use client'

import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { useRouter } from '@/i18n'

const ImpersonatingHeader = () => {
  const { data: session, update } = useSession()
  const router = useRouter()
  const t = useTranslations('ImpersonatingHeader')

  const stopImpersonating = async () => {
    console.log('stopping impersonation')
    const response = await fetch('/api/auth/impersonate/cancel', {
      method: 'GET',
    })
    if (response.ok) {
      const body = await response.json()
      await update({
        isImpersonating: false,
        school: body.school,
        role: body.role,
      })
      router.refresh()
    }
  }

  return (
    <p className="bg-deep-orange-300 block py-4 text-center text-lg font-bold leading-relaxed">
      {t('youAreCurrentlyViewing')}
      <span className="cursor-pointer underline underline-offset-2" onClick={stopImpersonating}>
        {' '}
        {t('clickHereToStop')}
      </span>
    </p>
  )
}

export default ImpersonatingHeader
