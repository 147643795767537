'use client'

import { useSession } from 'next-auth/react'
import { MutableRefObject, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { createNewChat, generateSystemPromptText } from '@/components/Chat/chat.utils'
import { useClientConfig } from '@/components/Chat/useConfig'

interface ChatbotProviderProps extends React.PropsWithChildren {}

interface ChatbotContextProps {
  initialPrompt: string | undefined
  setInitialPrompt: (initialPromptValue: string) => void
  refreshChat: () => void
  chatId: string | undefined
  isLoading: boolean
  breadcrumbs: string[]
  setBreadcrumbs: (val: string[]) => void
  recLetterResponse: string | null
  setRecLetterResponse: (val: string) => void
  selectedBreadcrumbIndex: MutableRefObject<number> | null
  inputValue: string
  setInputValue: (value: string) => void
  resetInputValue: () => void
}

export const ChatbotContext = createContext<ChatbotContextProps>({
  initialPrompt: undefined,
  setInitialPrompt: () => null,
  refreshChat: () => null,
  chatId: undefined,
  isLoading: false,
  breadcrumbs: [],
  setBreadcrumbs: () => null,
  recLetterResponse: null,
  setRecLetterResponse: () => null,
  selectedBreadcrumbIndex: null,
  inputValue: '',
  setInputValue: () => null,
  resetInputValue: () => null,
})

export const ChatbotProvider = ({ children }: ChatbotProviderProps) => {
  const { chatAPI } = useClientConfig()

  const [chatId, setChatId] = useState(crypto.randomUUID())
  const [isLoading, setIsLoading] = useState(false)

  const [initialPrompt, setInitialPrompt] = useState<string | undefined>(undefined)
  const { data: session } = useSession()

  const [isChatCreated, setIsChatCreated] = useState<boolean>(false)

  const [defaultSystemPromptText, setDefaultSystemPromptText] = useState<string>('')

  const isLoadingRef = useRef(false)

  const [breadcrumbs, setBreadcrumbs] = useState<string[]>(['home'])

  const [recLetterResponse, setRecLetterResponse] = useState<string | null>(null)

  const selectedBreadcrumbIndex = useRef(0)

  const [inputValue, setInputValue] = useState<string>('')

  const resetInputValue = () => {
    setInputValue('')
  }

  const userSystemPromptText = useMemo(() => {
    const email = session?.user.email

    const role = session?.user.role
    if (!email || !role) return undefined

    return generateSystemPromptText(email, role, defaultSystemPromptText)
  }, [defaultSystemPromptText, session?.user.email, session?.user.role])

  const fetchDefaultSystemPrompt = useCallback(() => {
    if (isLoadingRef.current === false && session?.token) {
      isLoadingRef.current = true

      // This needs to be changed to fetch only default prompt
      fetch(`${chatAPI}/system-prompt/default`, {
        headers: {
          Authorization: `Bearer ${session?.token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const fetchedDefaultPromptText = (data as { text: string }).text
          if (!!fetchedDefaultPromptText) setDefaultSystemPromptText(fetchedDefaultPromptText)
        })
        .catch((error) => {
          console.error('Error fetching config', error)
          setIsLoading(false)
        })
    }
  }, [chatAPI, session])

  useEffect(() => {
    fetchDefaultSystemPrompt()
  }, [fetchDefaultSystemPrompt])

  const createChat = useCallback(() => {
    if (!isChatCreated && !!userSystemPromptText && chatAPI) {
      // Always use default system prompt: See https://linear.app/college-guidance-network/issue/COL-500/defect-prod-and-dev-ava-front-end-incorrectly-sends-old-system-prompt
      //createNewChat(chatAPI, chatId, userSystemPromptText, session?.token)
      createNewChat(chatAPI, chatId, 'default', session?.token)
        .then(() => {
          setIsChatCreated(true)
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error('Error creating config', error)
          setIsLoading(false)
        })
    }
  }, [chatAPI, chatId, isChatCreated, userSystemPromptText])

  useEffect(() => {
    if (!isChatCreated && !!userSystemPromptText) createChat()
  }, [createChat, isChatCreated, userSystemPromptText])

  const refreshAvaContent = () => {
    setChatId(crypto.randomUUID())
    setIsChatCreated(false)
  }

  return (
    <ChatbotContext.Provider
      value={{
        initialPrompt,
        setInitialPrompt,
        refreshChat: refreshAvaContent,
        chatId,
        isLoading,
        breadcrumbs,
        setBreadcrumbs,
        recLetterResponse,
        setRecLetterResponse,
        selectedBreadcrumbIndex,
        inputValue,
        setInputValue,
        resetInputValue,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}

export const useChatbot = () => {
  const value = useContext(ChatbotContext)
  return value
}

export default ChatbotContext
