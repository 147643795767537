export interface DataTypeSpec {
  name: string
  table: string
  airtableSheet: string
  airtableBase: string
}

export const dataTypes: { [key: string]: DataTypeSpec } = {
  taxonomy: {
    name: 'Taxonomy',
    table: 'taxonomy',
    airtableBase: 'CGN Programming',
    airtableSheet: 'Taxonomy',
  },
}
