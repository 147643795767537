'use client'

import { UserCircleIcon } from '@heroicons/react/20/solid'
import { UserIcon } from '@heroicons/react/24/outline'
import { Button, Menu, MenuHandler, MenuList } from '@material-tailwind/react'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

import BasicInfo from '@/components/Preferences/BasicInfo'
import SmsNudges from '@/components/Preferences/SmsNudges'
import { useSchool } from '@/providers/SchoolProvider'

import { pathways } from '@roadmap/lib/constants'
import { I18N_LANGUAGE_NAMES } from '@roadmap/lib/i18n'
import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'
import { Locales } from '@roadmap/lib/types/data'

import AvatarMenuModal from '../AvatarMenu/AvatarMenuModal'
import ImpersonateForm from '../AvatarMenu/ImpersonateForm'
import EditProfile from '../EditProfile/EditProfile'

interface Props {
  locale?: Locales
  availableSchools?: { [key: string]: string } | null
  availableRoles?: UserRoles[] | null
  availablePathways: { [key: string]: string[] }
}

const AboutYou = (props: Props) => {
  const { locale = 'en', availableSchools, availableRoles, availablePathways } = props
  const { data: session } = useSession()
  const t = useTranslations('AboutYou')
  const tC = useTranslations('CollegeTimeline')
  const tP = useTranslations('Pathways')
  const tPC = useTranslations('PathwayCategories')
  const { school } = useSchool()
  const [avatarMenuOpen, setAvatarMenuOpen] = useState<boolean>(false)
  const [editProfileModalOpen, setEditProfileModalOpen] = useState<boolean>(false)
  const [impersonateModalOpen, setImpersonateModalOpen] = useState<boolean>(false)
  const [editSettingsModalOpen, setEditSettingsModalOpen] = useState<boolean>(false)

  const handleUpdateState = (value: boolean) => {
    setEditProfileModalOpen(value)
  }

  const handleUpdateSettingsState = (value: boolean) => {
    setEditSettingsModalOpen(value)
  }

  const showImpersonateModal = () => {
    setImpersonateModalOpen(true)
    setAvatarMenuOpen(false)
  }
  useEffect(() => {
    if (avatarMenuOpen) {
      setEditProfileModalOpen(false)
      setEditSettingsModalOpen(false)
      setImpersonateModalOpen(false)
    }
  }, [avatarMenuOpen])

  const handleToggle = () => {
    setAvatarMenuOpen((prev) => !prev)
  }

  const userCircleIconRef = useRef<SVGSVGElement>(null)
  const menuRef = useRef<HTMLUListElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      userCircleIconRef.current &&
      !userCircleIconRef.current.contains(event.target as Node)
    ) {
      setAvatarMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <Menu open={avatarMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button className="cursor-pointer bg-brand-purple mx-2 md:mr-2 px-3 sm:px-3 md:px-4 xl:px-6 relative">
            <UserCircleIcon
              ref={userCircleIconRef}
              onClick={handleToggle}
              className="absolute left-0 top-0 md:-top-[2px] md:h-7 md:w-full w-6 h-6 text-white"
            />
          </Button>
        </MenuHandler>
        <MenuList
          ref={menuRef}
          className="w-full h-auto overflow-y-auto scrollbar-none lg:w-1/2 2xl:w-2/5 rounded-none p-5 -top-1 mt-3"
        >
          <div className="flex flex-col gap-5 outline-none">
            <div className="flex flex-col w-full gap-2 justify-center">
              <div className="flex flex-row items-center h-8 gap-5 bg-white">
                <UserIcon className="md:h-8 md:w-8 w-10 h-10 text-black" />
                <span className="font-bold text-black text-3xl">{t('aboutYou')}</span>
              </div>
              <p className="text-black text-base font-normal">
                {t('toMakeSureYouGetTheBestExperienceMakeSureAllThisInformationIsCorrect')}
              </p>
            </div>
            <div className="flex flex-col w-full md:w-fit md:flex-row gap-2 h-30 py-4 px-6 md:h-16 bg-brand-tan md:gap-8 text-center rounded-lg">
              <div className="flex flex-col gap-3 md:flex-row items-center justify-around">
                <UserIcon className="md:h-7 md:w-8 w-10 h-8 text-black" />

                {school?.schoolName && (
                  <span className="block truncate text-base text-black font-normal">
                    {school.schoolName}{' '}
                    {session?.user.graduationYear && t('classOf', { gradYear: session?.user.graduationYear })}
                  </span>
                )}
              </div>
              <div className="flex flex-col md:flex-row items-center text-black">
                <span className="font-normal text-base">
                  {t('userType')}: {session?.user.role ? t(session?.user.role) : 'Not yet set'}
                </span>
              </div>
            </div>
            {editProfileModalOpen ? (
              <EditProfile availablePathways={availablePathways} updateState={handleUpdateState} />
            ) : editSettingsModalOpen ? (
              <>
                <BasicInfo />
                <SmsNudges updateState={handleUpdateSettingsState} />
              </>
            ) : (
              <div className="flex flex-row">
                <div className="flex flex-col text-black gap-3">
                  <div className="grid grid-row gap-1">
                    <p className="text-base">
                      {tC('takeALookAtThe')}{' '}
                      <Link
                        className="cursor-pointer underline text-brand-purple text-base font-bold"
                        href={locale === 'en' ? '/college-timeline/' : `/${locale}/college-timeline/`}
                      >
                        {tC('collegePrepTimeline')}
                      </Link>
                    </p>
                  </div>
                  {session?.user.isCGN && (
                    <>
                      <div className="grid grid-row md:grid-cols-[224px_minmax(250px,_1fr)_50px] gap-1">
                        <span className="text-base font-bold text-black w-56">{t('administration')}:</span>
                        <Link
                          className="cursor-pointer underline text-brand-purple text-base font-bold"
                          href={locale === 'en' ? '/admin/cgn/' : `/${locale}/admin/cgn/`}
                        >
                          {t('cgnSiteAdministration')}
                        </Link>
                      </div>
                      <div className="grid grid-row md:grid-cols-[224px_minmax(250px,_1fr)_50px] gap-1">
                        <span className="text-base font-bold text-black w-56">{t('impersonate')}:</span>
                        <span
                          className="cursor-pointer underline text-brand-purple text-base font-bold"
                          onClick={showImpersonateModal}
                        >
                          {t('impersonateSchool/role')}
                        </span>
                      </div>
                    </>
                  )}

                  <div className="grid grid-row md:grid-cols-[224px_minmax(250px,_1fr)_50px] gap-1">
                    <span className="text-base font-bold text-black w-56">
                      <span>{t('language')}:</span>
                    </span>
                    <span className="text-base text-black">{t(I18N_LANGUAGE_NAMES[locale])}</span>
                  </div>
                  {Object.keys(pathways)
                    .filter((category) =>
                      pathways[category].some((pathway) => session?.user.pathways?.includes(pathway))
                    )
                    .filter((category) => category !== 'Types of Schools') // Remove the Types of Schools on frontend with request in COL-386
                    .map((category) => (
                      <div key={category} className="grid grid-row md:grid-cols-[224px_minmax(250px,_1fr)_50px] gap-1">
                        <span className="text-base font-bold text-black">{tPC(category)}:</span>
                        <span className="text-base text-black">
                          {pathways[category]
                            .filter((pathway) => session?.user.pathways?.includes(pathway))
                            .map((pathway) => tP(pathway))
                            .join(', ')}
                        </span>
                      </div>
                    ))}
                  <div
                    className={`${editProfileModalOpen ? 'hidden' : 'visible'} flex flex-col sm:flex-row gap-4 md:gap-6 mb-10 whitespace-nowrap`}
                  >
                    <Button
                      className="w-auto bg-brand-purple text-sm font-sans"
                      onClick={() => setEditProfileModalOpen(true)}
                    >
                      {t('editProfile')}
                    </Button>
                    <Button
                      className="w-auto bg-brand-purple text-sm font-sans"
                      onClick={() => setEditSettingsModalOpen(true)}
                    >
                      {t('settings')}
                    </Button>
                    <Button
                      className="w-auto cursor-pointer bg-white text-black text-sm font-sans"
                      onClick={() => signOut({ callbackUrl: locale === 'en' ? '/' : `/${locale}` })}
                    >
                      {t('logOut')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </MenuList>
      </Menu>

      {session?.user.isCGN && availableSchools && availableRoles && (
        <AvatarMenuModal
          title={t('impersonateSchool/role')}
          modalIsOpen={impersonateModalOpen}
          setModalIsOpen={setImpersonateModalOpen}
        >
          <ImpersonateForm
            availableSchools={availableSchools}
            availableRoles={availableRoles}
            setImpersonateModalOpen={setImpersonateModalOpen}
          />
        </AvatarMenuModal>
      )}
    </>
  )
}

export default AboutYou
