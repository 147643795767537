/**
 * NOTE: In the event that any pathway or category names are changed, translations
 * must be provided.  The translation namespaces are, respectively, "Pathways"
 * and "PathwayCategories".
 *
 * Pathway Categories are, eg, "Academic Interests" or "Types of Schools"
 */
export const pathways: { [key: string]: string[] } = {
  Pathways: [
    'Four-Year Degree',
    'Two-Year Degree',
    'Career and Technical Education (CTE)',
    'Military Service',
    'Direct to Workforce',
  ],
  'Academic Interests': [
    'STEM',
    'Liberal Arts/Humanities',
    'Business',
    'Healthcare',
    'Performing or Visual Arts',
    'Gap Year',
  ],
  'Types of Schools': [
    'International',
    'Highly Selective',
    'HBCUs (Historically Black Colleges & Universities)',
    'HSIs (Hispanic-Serving Institutions)',
    'University of California System',
  ],
  'Personal/Family Context': [
    'Guidance for First-Generation College Students',
    'Guidance for LGBTQ+ Students',
    'Guidance for Black Students',
    'Prospective College Athletes',
    'Financial Aid',
    'Divorce/Separation and College Financing',
    'Learning and Academic Support',
  ],
}

export const alsoAddPathways: { [key: string]: string[] } = {
  'Four-Year Degree': [
    'Family Dynamics (College)',
    'Money: Affordability',
    'Money: ROI/Value',
    'Types of Schools',
    'Building a List',
    'Fit',
    'Majors',
    'High School Course Selection/Transcript',
    'Standardized Testing',
    'Essays',
    'Extracurricular Activities',
    'College Visits',
    'Summer (College)',
    'Letters of Recommendation',
    'Application Platforms',
    'Decision Plans',
    'Decisions',
    'Waitlist',
    'Deferrals',
    'Transition to College',
  ],
}

export const alwaysAddPathways: string[] = [
  'Family Dynamics',
  'Career - Exploring',
  'Career - Developing Skills',
  'Career - Finding Jobs',
  'Career - Summer',
  'Entire School Community',
]

export const statePathways: { [key: string]: string[] } = {
  AL: ['Alabama'],
  AK: ['Alaska'],
  AS: ['American Samoa'],
  AZ: ['Arizona'],
  AR: ['Arkansas'],
  CA: ['California'],
  CO: ['Colorado'],
  CT: ['Connecticut'],
  DE: ['Delaware'],
  DC: ['District Of Columbia'],
  FL: ['Florida'],
  GA: ['Georgia'],
  GU: ['Guam'],
  HI: ['Hawaii'],
  ID: ['Idaho'],
  IL: ['Illinois'],
  IN: ['Indiana'],
  IA: ['Iowa'],
  KS: ['Kansas'],
  KY: ['Kentucky'],
  LA: ['Louisiana'],
  ME: ['Maine'],
  MD: ['Maryland'],
  MA: ['Massachusetts'],
  MI: ['Michigan'],
  MN: ['Minnesota'],
  MS: ['Mississippi'],
  MO: ['Missouri'],
  MT: ['Montana'],
  NE: ['Nebraska'],
  NV: ['Nevada'],
  NH: ['New Hampshire'],
  NJ: ['New Jersey'],
  NM: ['New Mexico'],
  NY: ['New York'],
  NC: ['North Carolina'],
  ND: ['North Dakota'],
  MP: ['Northern Mariana Is'],
  OH: ['Ohio'],
  OK: ['Oklahoma'],
  OR: ['Oregon'],
  PA: ['Pennsylvania'],
  PR: ['Puerto Rico'],
  RI: ['Rhode Island'],
  SC: ['South Carolina'],
  SD: ['South Dakota'],
  TN: ['Tennessee'],
  TX: ['Texas'],
  UT: ['Utah'],
  VT: ['Vermont'],
  VA: ['Virginia'],
  VI: ['Virgin Islands'],
  WA: ['Washington'],
  WV: ['West Virginia'],
  WI: ['Wisconsin'],
  WY: ['Wyoming'],
}

export const allPathways = [
  ...pathways.Pathways,
  ...pathways['Academic Interests'],
  ...pathways['Types of Schools'],
  ...pathways['Personal/Family Context'],
  ...alsoAddPathways['Four-Year Degree'],
  ...alwaysAddPathways,
]
