'use client'

import { GoogleTagManager } from '@next/third-parties/google'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { SchoolRecord, UserRecord } from '@roadmap/lib/types/data'

interface Props {
  gtmId: string
  user?: UserRecord | null
  school?: SchoolRecord | null
}
const GTMHeader = (props: Props) => {
  const { gtmId, user, school } = props
  const pathname = usePathname()

  useEffect(() => {
    window.dataLayer?.push({
      event: 'page_view',
      page: pathname,
      url: window.location.href,
      userId: user?.id,
      email: user?.email,
      role: user?.role,
      schoolId: school?.id,
      schoolName: school?.schoolName,
      studentName: user?.studentName,
      firstName: user?.firstName,
      lastName: user?.lastName,
    })
  }, [pathname, user, school])

  return <GoogleTagManager gtmId={gtmId} />
}

export default GTMHeader
