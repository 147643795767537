'use client'

import { useSession } from 'next-auth/react'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

interface Props extends React.PropsWithChildren {
  title: string
  roles?: UserRoles[]
}

const PreferenceContainer = (props: Props) => {
  const { title, roles, children } = props
  const { data: session } = useSession()
  if (roles && session?.user.role && !roles.includes(session.user.role)) {
    return <></>
  }
  return (
    <section className="mt-2">
      <div>
        <p className="text-2xl font-bold text-black">{title}</p>
      </div>
      <div className="mt-2 text-black">{children}</div>
    </section>
  )
}

export default PreferenceContainer
