import { createSharedPathnamesNavigation } from 'next-intl/navigation'
import { getRequestConfig } from 'next-intl/server'

import { getSupportedLocales } from '@roadmap/lib/i18n'

// Can be imported from a shared config
export const LOCALES = getSupportedLocales()
export const LOCALE_PREFIX = 'as-needed'

export const { Link, useRouter, usePathname, redirect } = createSharedPathnamesNavigation({
  locales: LOCALES,
  localePrefix: LOCALE_PREFIX,
})

export default getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  const validLocale = LOCALES.includes(locale as any) ? locale : 'en'

  return {
    messages: (await import(`../messages/${validLocale}.json`)).default,
  }
})
