'use client'

import { useSession } from 'next-auth/react'
import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react'

import { SchoolRecord } from '@roadmap/lib/types/data'

interface SchoolProviderProps extends React.PropsWithChildren {
  school: SchoolRecord | null
  schools: SchoolRecord[]
}

interface SchoolContextProps {
  school: SchoolRecord | null
  setSchool?: Dispatch<SetStateAction<SchoolRecord | null>>
  schools: SchoolRecord[]
}

export const SchoolContext = createContext<SchoolContextProps>({ school: null, schools: [] })

export const SchoolProvider = ({ school, schools = [], children }: SchoolProviderProps) => {
  const { data: session, status } = useSession()
  const [currentSchool, setCurrentSchool] = useState<SchoolRecord | null>(school)
  const [schoolsList, setSchoolsList] = useState<SchoolRecord[]>([])

  useMemo(() => {
    const getSchoolData = async () => {
      if (status === 'authenticated') {
        if (session?.user.school) {
          const response = await fetch(`/api/school/${session.user.school}`, {
            method: 'GET',
          })
          if (response.ok) {
            const body = await response.json()
            setCurrentSchool(body as SchoolRecord)
          }
        }
      } else if (status === 'unauthenticated') {
        setCurrentSchool(null)
      }
    }
    getSchoolData()
  }, [session?.user.school, status])

  useMemo(() => {
    setSchoolsList(schools)
  }, [schools])

  return (
    <SchoolContext.Provider value={{ school: currentSchool, setSchool: setCurrentSchool, schools: schoolsList }}>
      {children}
    </SchoolContext.Provider>
  )
}

export const useSchool = () => {
  const value = useContext(SchoolContext)
  return value
}

export default SchoolContext
