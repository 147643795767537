'use client'

import { Alert } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useRouter } from '@/i18n'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'

interface Props {
  availableSchools: { [key: string]: string }
  availableRoles: UserRoles[]
  setImpersonateModalOpen: Dispatch<SetStateAction<boolean>>
}

interface Inputs {
  schoolId: string
  role: string
}

const ImpersonateForm = (props: Props) => {
  const { availableSchools, availableRoles, setImpersonateModalOpen } = props
  const router = useRouter()
  const t = useTranslations('Forms')
  const { data: session, update } = useSession()
  const { register, handleSubmit } = useForm<Inputs>()
  const [error, setError] = useState<string | null>(null)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setError(null)
    try {
      const response = await fetch('/api/auth/impersonate', {
        method: 'POST',
        body: JSON.stringify({
          schoolId: data.schoolId,
          role: data.role,
        }),
      })
      if (response.status === 204) {
        // Impersonation request validated, update session with it
        console.log('Impersonation request succeeded, setting new values')
        await update({
          school: data.schoolId,
          role: data.role,
          isImpersonating: true,
        })
        setImpersonateModalOpen(false)
        router.refresh()
      } else {
        const body = await response.json()
        if (body.status === 'error' && body.error) {
          setError(body.error)
        }
      }
    } catch (error) {
      console.error('Error submitting impersonation request', error)
      setError(t('unknownError'))
    }
  }

  return (
    <div>
      <p className="text-2xl font-bold leading-relaxed">{t('viewAnother')}</p>
      {error && (
        <Alert color="red" className="block">
          {t('error', { error: error })}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-4 block">
          <select {...register('schoolId', { required: true })} defaultValue={session?.user.school}>
            {Object.keys(availableSchools).map((schoolId) => (
              <option key={schoolId} value={schoolId}>
                {availableSchools[schoolId]}
              </option>
            ))}
          </select>
        </div>
        <div className="my-4 block">
          <select {...register('role', { required: true })} defaultValue={session?.user.role}>
            {availableRoles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
        <div className="my-4 block">
          <button type="submit" className="bg-brand-purple-500 rounded-md px-6 py-4 text-white">
            {t('submit')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ImpersonateForm
