'use client'

import { Option, Select } from '@material-tailwind/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { startTransition } from 'react'

import { usePathname, useRouter } from '@/i18n'
import { useSettings } from '@/providers/SettingsProvider'

import { I18N_LANGUAGE_NAMES } from '@roadmap/lib/i18n'
import { Locales } from '@roadmap/lib/types'

const LanguageSwitcherDropdown = (): JSX.Element => {
  const t = useTranslations('EditProfile')
  const router = useRouter()
  const pathname = usePathname()
  const { data: session, update } = useSession()
  const { SUPPORTED_LOCALES } = useSettings()

  const onLanguageChange = (locale: Locales) => {
    return async () => {
      if (session?.user) {
        await update({ locale: locale })
      }
      startTransition(() => {
        router.replace(pathname, { locale: locale })
      })
    }
  }
  return (
    <div className="relative w-72 left-0 md:left-28">
      <Select label={t('selectYourLanguage')} value={I18N_LANGUAGE_NAMES[session?.user?.locale ?? 'en']}>
        {SUPPORTED_LOCALES.map((locale) => {
          return (
            <Option value={I18N_LANGUAGE_NAMES[locale]} key={locale} onClick={onLanguageChange(locale)}>
              {I18N_LANGUAGE_NAMES[locale]}
            </Option>
          )
        })}
      </Select>
    </div>
  )
}

export default LanguageSwitcherDropdown
